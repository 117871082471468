import { useGetAllMagazineQuery } from '@/generated/graphql';
import Slider from 'react-slick';
import { NextIcon, PrevIcon } from '@/assets/icons';
import { useRef } from 'react';
import backgroundInsight from '@/assets/images/background_insight.png';
import Image from 'next/image';
import { OtherInsightHomepageParams } from '@/constants/defaultParams';
import { settings } from '@/configs/settingSlider';
import MagazineItemHome from '../Insights/MagazineItemHome';

const OtherInsightSection = () => {
  const customSlider = useRef<Slider>(null);
  const goToPrev = () => customSlider?.current?.slickPrev();
  const goToNext = () => customSlider?.current?.slickNext();
  const { data: dataInsight } = useGetAllMagazineQuery({
    variables: OtherInsightHomepageParams,
  });

  return (
    <div className="relative">
      <Image
        src={backgroundInsight || ''}
        alt={'background empty'}
        className="absolute z-[-10] h-full w-full object-none"
      />
      <div className="container flex flex-col gap-16 py-16">
        <p className="border-neutral-grayLight font-header w-full border-b-[1px] text-center text-[32px] font-bold uppercase text-white">
          Các số tạp chí
        </p>
        <div className="relative w-full">
          <Slider {...settings} ref={customSlider} arrows={false}>
            {dataInsight?.magazine?.slice(1)?.map(item => {
              const {
                id,
                description,
                thumbnail,
                name_pdf,
                created_at,
                slug,
                title,
                index,
              } = item;
              return (
                <div key={index}>
                  <MagazineItemHome
                    id={id}
                    desc={description}
                    thumbnailUrl={thumbnail}
                    pdfUrl={name_pdf}
                    public_at={created_at}
                    create_at={created_at}
                    slug={slug}
                    title={title}
                    index={index}
                  />
                </div>
              );
            })}
          </Slider>
          <div className="absolute left-[-50px] top-1/2 hidden -translate-y-1/2 lg:block">
            <button onClick={goToPrev} aria-label="prevBtn">
              <PrevIcon className="cursor-pointer fill-transparent" />
            </button>
          </div>
          <div className="absolute right-[-50px] top-1/2 hidden -translate-y-1/2 lg:block">
            <button onClick={goToNext} aria-label="nextBtn">
              <NextIcon className="cursor-pointer fill-transparent" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherInsightSection;
