import React from 'react';
import Link from 'next/link';
import { LazyImage } from '@/components/shared';
import _format from '@/utils/format';
import { UrlLink } from '@/configs/urlLink';
import { getTitleMagazineByIndex } from '@/utils';

interface MagazineItemProps {
  pdfUrl?: string | null;
  thumbnailUrl?: string | null;
  create_at?: any | null;
  public_at?: any | null;
  desc?: string | null;
  title?: string | null;
  slug?: string | null;
  id: number;
  index?: number | null;
}
const MagazineItemHome = ({ slug, thumbnailUrl, index }: MagazineItemProps) => {
  return (
    <Link
      className="flex h-[353px] w-full flex-col justify-start gap-4 rounded-xl p-4 shadow-[2px_2px_10px_0_#05BFCB14] "
      href={`${UrlLink.insight.index}/${slug}`}
    >
      <div>
        <div className="relative h-[300px] lg:h-[350px]">
          <LazyImage
            alt={thumbnailUrl || ''}
            src={thumbnailUrl || ''}
            fill
            sizes="(max-width: 768px) 20vw, (max-width: 1024px) 30vw, 10vw"
            className="object-fill"
          />
        </div>
        <div className="flex items-center justify-center">
          <p className="text-PC-Button-Small md:text-PC-Button-Large mt-4 line-clamp-1 text-center text-[#000000] md:text-[#000000]">
            {getTitleMagazineByIndex(index || 0)}
          </p>
        </div>
      </div>
    </Link>
  );
};

export default MagazineItemHome;
