import dynamic from 'next/dynamic';
import { SVGProps } from 'react';

export const StarIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./star.svg'),
);
export const StarOutlineIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('./star-outline.svg'),
);
export const Logo = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/logo.svg'),
);
export const FacebookIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/facebook.svg'),
);
export const TiktokIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/tiktok.svg'),
);
export const YoutubeIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/youtube.svg'),
);
export const LikeIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/like.svg'),
);
export const UnLikeIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/unlike.svg'),
);
export const UnLikedIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/unliked.svg'),
);
export const ShareIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/share.svg'),
);
export const PlayingIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/playing.svg'),
);
export const ListDash = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/listdash.svg'),
);
export const PlayIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/play_icon.svg'),
);
export const LikedIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/liked.svg'),
);
export const BreadIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/breakcrumb.svg'),
);
export const HideIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/InteractIcon/hide-interact.svg'),
);
export const ShareFB = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/InteractIcon/share-fb-interact.svg'),
);
export const ShareIN = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/InteractIcon/share-ins-interact.svg'),
);
export const ShareLI = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/InteractIcon/share-lin-interact.svg'),
);
export const CopyUrl = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/InteractIcon/copy-url-interact.svg'),
);
export const StarUnFill = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/InteractIcon/star-unfill.svg'),
);
export const StarFilled = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/InteractIcon/star-filled.svg'),
);
export const StarHalfFilled = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/InteractIcon/star-halffill.svg'),
);
export const CupIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/cup.svg'),
);
export const ChartIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/chart.svg'),
);
export const HeadQuarterIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/head-quarter.svg'),
);
export const LicenseIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/license.svg'),
);
export const PaymentIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/payment.svg'),
);
export const ShieldIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/shield.svg'),
);

export const TickIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/tick.svg'),
);
export const SearchIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/search.svg'),
);

export const NextIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/nextIcon.svg'),
);
export const PrevIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/prevIcon.svg'),
);
export const ThreeStarsIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/3starsIcon.svg'),
);
export const ArrowDropDownIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/arrow_drop_down.svg'),
);
export const PlayBtnWhiteIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/play_btn_white.svg'),
);
export const ArrowRightIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/arrow_right.svg'),
);
export const TickBadgeIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/tick_badge.svg'),
);
export const ToUsIcon1 = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/ToUs1.svg'),
);
export const ToUsIcon2 = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/ToUs2.svg'),
);
export const ToUsIcon3 = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/ToUs3.svg'),
);
export const StarBadgeIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/star_badge.svg'),
);
export const RightArrowIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/rightArrow.svg'),
);
export const SortVector = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/SortVector.svg'),
);

export const LikeIconVector = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/like_icon.svg'),
);

export const ArrowRightIconVector = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/arrow_right_icon.svg'),
);

export const DislikeIconVector = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/dislike_icon.svg'),
);

export const ShareIconVector = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/share_icon.svg'),
);

export const ArrowRight1IconVector = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/arrow_right_1_icon.svg'),
);

export const ArrowLeft1IconVector = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/arrow_left_1_icon.svg'),
);

export const PlayPlaylist = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/PlayPlaylist.svg'),
);
export const BalanceIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/balance.svg'),
);
export const NextVideo = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/NextVideo.svg'),
);
export const PlayVideo = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/PlayVideo.svg'),
);
export const CloseButton = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/close.svg'),
);
export const LeftArrowIcon1 = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/LeftArrowIcon.svg'),
);
export const RightArrowIcon1 = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/RightArrowIcon.svg'),
);
export const ArrowDropUpIcon = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/arrow_drop_up.svg'),
);
export const LogoFooter = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/logoFooter.svg'),
);
export const TiktokInteration = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/InteractIcon/tiktok.svg'),
);
export const YoutubeInteration = dynamic<SVGProps<SVGSVGElement>>(
  () => import('@/assets/icons/InteractIcon/youtube.svg'),
);
