export const UrlLink = {
  knowledge: {
    knowledge: '/video-kien-thuc',
    playlist: '/video-kien-thuc',
    series: '/bai-viet-kien-thuc',
  },
  news: {
    news: '/tin-tuc',
    category: '/tin-tuc',
  },
  blog: {
    index: '/blog',
    detail: '',
    category: '/blog',
  },
  broker: {
    index: '/danh-gia-san',
    listing: '/danh-gia-san/listing',
    listingOrderByExpertScore:
      '/danh-gia-san?keyword=&page=1&order-by=expert-score',
    listingOrderByUserScore:
      '/danh-gia-san?keyword=&page=1&order-by=user-score',
  },
  insight: {
    index: '/insight',
  },
};
